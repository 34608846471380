import { useEffect, useRef, useState } from 'react';
import { Radio24, RadioSelected24, Star32, TemplateRating32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';
import { useConfig, useCookies } from '@packages/utilities';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { Button } from '../Button/Button';
import { messages } from './KimRating.messages';
import { Dialog } from '../Dialog';
import type { KimRatingProps, RatingIndex, RatingValue } from './KimRating.types';
import { KimRatingDialogContent } from './KimRating.dialogContent';

/**
 * Renders the KimRating component to pickup feedback from customers
 *
 * @param props - The component props consist of pageType (storefront | search | detailpage) and
 * templateParams that override the default query params against the kim backend
 *
 * @returns The rendered customer feedback component.
 */
export const KimRating = ({ pageType, templateParams }: KimRatingProps) => {
  const { getCookies } = useCookies();
  const { appView } = getCookies();
  const [ratingValue, setRatingValue] = useState<RatingIndex | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const config = useConfig();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // A11Y-411: focus the button when the ratingValue changes
  useEffect(() => {
    buttonRef.current?.focus();
  }, [ratingValue]);

  // "kim "is renamed in config to "customerCentricFeedback"

  if (appView === 'true' || !config.customerCentricFeedback.enabled) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {/* position relative is needed for the speech bubble to be displayed above the colored box */}
      <Box sx={{ position: 'relative' }}>
        <TemplateRating32 sx={{ width: '5rem', height: '5rem', mt: 1.5 }} />
      </Box>
      <Box
        sx={{
          bgcolor: 'grey.light',
          width: '100%',
          // make this margin-padding-switch to let the speech bubble overlap the colored container
          mt: -6,
          pt: 6,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingInline: 6,
        }}
      >
        <Typography variant="body3">{formatMessage(messages.helpUs)}</Typography>
        <Typography variant="body1" sx={{ pt: 1 }}>
          {pageType === 'storefront' && formatMessage(messages.questionStorefront)}
          {pageType === 'detailpage' && formatMessage(messages.questionDetailpage)}
          {pageType === 'search' && formatMessage(messages.questionSearch)}
        </Typography>
        <Box
          sx={{
            display: 'inline-flex',
            pt: 3.5,
            maxWidth: '19.5rem',
            justifyItems: 'center',
          }}
        >
          {[
            formatMessage(messages.ratingVeryUnsatisfied),
            formatMessage(messages.ratingUnsatisfied),
            formatMessage(messages.ratingNor),
            formatMessage(messages.ratingSatisfied),
            formatMessage(messages.ratingVerySatisfied),
          ].map((text, index) => (
            <Button
              key={text}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'top',
                justifyContent: 'flex-start',
                flex: 1,
                paddingInline: '0.1rem',
                color: 'inherit',
                textDecoration: 'inherit',
                border: 'none',
              }}
              onClick={(e) => {
                e.preventDefault();
                setRatingValue(index as RatingIndex);
              }}
            >
              <Star32
                sx={{
                  width: '1.7rem',
                  height: '1.7rem',
                  stroke: 'black',
                  color: ratingValue === null || ratingValue < index ? 'white' : 'kim.main',
                }}
              />
              <Typography variant="body3" sx={{ mt: 1, fontSize: '0.6rem' }}>
                {text}
              </Typography>
            </Button>
          ))}
        </Box>
        <Box sx={{ pt: 5, pb: 2.25, visibility: ratingValue === null ? 'hidden' : 'visible' }}>
          <Dialog
            open={modalOpen}
            title={formatMessage(messages.helpUs)}
            handleClose={() => setModalOpen(false)}
            dialogContent={
              // make the dialog content conditional to dynamically load further complex hooks
              ratingValue !== null && (
                <KimRatingDialogContent
                  pageType={pageType}
                  ratingValue={(ratingValue + 1) as RatingValue}
                  templateParams={templateParams}
                />
              )
            }
          />
          <Button
            ref={buttonRef}
            variant="outlined"
            onClick={() => setModalOpen(true)}
            sx={{ minWidth: '12rem' }}
          >
            {formatMessage(messages.ratingOnwards)}
          </Button>
          <Box>
            <RadioSelected24 sx={{ width: '0.5rem', height: '0.5rem' }} />
            <Radio24 sx={{ width: '0.5rem', height: '0.5rem' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
